import { primaryColor, redColor } from 'assets/styles/jss/main';

const multipleWeeksPickerStyle = {
  root: {
    overflow: 'auto',
  },
  errorText: {
    color: redColor,
  },
  container: {
    position: 'relative',
  },
  paperAndArrows: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    alignItems: 'center',
    width: '100%',
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    background: primaryColor,
    color: '#FFF',
    fontSize: '30px',
    width: 'fit-content',
    padding: '15px',
    margin: '0 auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '0.8em',
    color: '#856404',
    backgroundColor: '#fff3cd',
    borderColor: '#ffeeba',
    borderRadius: '10px',
  },
  arrowCan: {
    cursor: 'pointer',
  },
  arrowCannot: {
    color: primaryColor,
  },
  arrowDropDown: {
    position: 'absolute',
    right: 5,
    bottom: 20,
    transition: 'transform .1s',
  },
  arrowDropDownOpen: {
    transform: 'rotate(180deg)',
  },
  selectedYear: {
    fontSize: '25px',
  },
};

export default multipleWeeksPickerStyle;
