import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import { ArrowBack, ArrowForward, ExpandMore } from '@material-ui/icons';

import SweetModal from 'components/SweetModal/SweetModal';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from './CustomInput';
import CustomChip from 'components/Miscellaneous/CustomChip';

import multipleWeeksPickerStyle from './style/multipleWeeksPickerStyle';

import {
  format,
  startOfISOWeekYear,
  endOfISOWeekYear,
  getISOWeeksInYear,
  addDays,
  addWeeks,
  getISOWeek,
  endOfISOWeek,
  startOfISOWeek,
} from 'date-fns';

import { toYearWeek } from '../../helpers/helpers';

const JULY = 6;
const AUGUST = 7;
const SEPTEMBER = 8;

class MultipleWeeksPicker extends Component {
  constructor() {
    super();
    this.handleOpen = this.handleOpen.bind(this);
  }

  state = {
    isOpen: false,
    selectedScholarYear: '',
    selectedWeeks: [],
    dateFormat: {
      toView: 'dd/MM/yyyy',
      toReturn: 'yyyy-MM-dd HH:mm:ss.SSS',
    },
    yearInfo: {},
    isPro: false,
  };

  _mounted = false;

  currentYear = new Date().getFullYear();
  currentDateWeek = getISOWeek(new Date());
  currentDay = new Date();

  currentScholarYear = () => {
    if (new Date().getMonth() >= AUGUST) {
      return `${this.currentYear}/${this.currentYear + 1}`;
    }
    return `${this.currentYear - 1}/${this.currentYear}`;
  };

  nextScholarYear = () => {
    if (new Date().getMonth() >= AUGUST) {
      return `${this.currentYear + 1}/${this.currentYear + 2}`;
    }
    return `${this.currentYear}/${this.currentYear + 1}`;
  };

  UNSAFE_componentWillMount() {
    this._mounted = true;
    this.setState(
      {
        selectedWeeks: [],
        selectedScholarYear: this.currentScholarYear(),
        isPro: this.props.isPro || this.state.isPro,
      },
      () => {
        this.addElements(
          'selectedWeeks',
          this.props.value.map((data) => data.year && data.week && (data.yearWeek || toYearWeek(data.year, data.week))),
        );
      },
    );
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  setState(state, callback) {
    return this._mounted && super.setState(state, callback);
  }

  // TODO refactor: extract in utils/helper file
  range = (start, end, step = 1) => {
    const allNumbers = [start, end, step].every(Number.isFinite);
    if (!allNumbers) throw new TypeError('range() expects only finite numbers as arguments.');
    if (step <= 0) throw new Error('step must be a number greater than 0.');
    if (start > end) step = -step;
    const length = Math.floor(Math.abs((end - start) / step)) + 1;
    return Array.from(Array(length), (x, index) => start + index * step);
  };

  inputLabel = () =>
    this.state.selectedWeeks.length === 0
      ? 'Choisissez les semaines'
      : `${this.state.selectedWeeks.length} semaine(s) sélectionnée(s)`;

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => this.setState({ isOpen: false });

  handlePrev = () =>
    this.setState((prevState) => ({
      ...prevState,
      selectedScholarYear: this.currentScholarYear(),
    }));

  handleNext = () =>
    this.setState((prevState) => ({
      ...prevState,
      selectedScholarYear: this.nextScholarYear(),
    }));

  getYearInfo = (year) => {
    if (this.state.yearInfo[year]) {
      return this.state.yearInfo[year];
    }

    const dateInYear = new Date(`${year}-07-01`);

    const start = startOfISOWeekYear(dateInYear);
    const end = endOfISOWeekYear(dateInYear);
    const nbWeeks = getISOWeeksInYear(dateInYear);

    const disabledWeeks = [];
    disabledWeeks.push(1); // NOUVEL AN
    disabledWeeks.push(43); // TOUSSAINT
    disabledWeeks.push(44); // TOUSSAINT
    disabledWeeks.push(getISOWeek(new Date(`${year}-12-24`))); // NOËL
    disabledWeeks.push(53); // SAINT SILVESTRE

    if (this.currentYear === year) {
      disabledWeeks.push(this.currentDateWeek);
    }

    const weeks = this.range(1, nbWeeks).map((week) => ({
      key: toYearWeek(year, week),
      year: Number(year),
      week,
      disabled: [...disabledWeeks].includes(week),
      start: addWeeks(start, week - 1),
      end: addDays(addWeeks(start, week - 1), 4),
    }));

    const toReturn = {
      start,
      end,
      nbWeeks,
      weeks,
    };

    this.setState((prevState) => ({
      ...prevState,
      yearInfo: { ...prevState.yearInfo, [year]: toReturn },
    }));

    return toReturn;
  };

  getWeekInfoFromWeekKey = (weekKey) => {
    const [year] = weekKey.toString().split('-').map(Number);
    const yearInfo = this.state.yearInfo[year] || this.getYearInfo(year);
    const weekInfo = yearInfo.weeks.find((i) => i.key === weekKey);
    if (!weekInfo) {
      console.error('Week', weekKey, 'not found in yearInfo for year', year);
    }
    return weekInfo;
  };

  convertSelectedWeeksToYearInfo = (selectedWeeks) => {
    return selectedWeeks.map((weekKey) => this.getWeekInfoFromWeekKey(weekKey)).filter((_) => _);
  };

  handleSubmit = () => {
    const selectedWeeksInYearInfoFormat = this.convertSelectedWeeksToYearInfo(this.state.selectedWeeks);
    this.props.onSelect(selectedWeeksInYearInfoFormat);
    this.handleClose();
  };

  onDeleteWeekChip = (weekKey) => {
    const selectedWeeksAfterDelete = this.state.selectedWeeks.filter((week) => week != weekKey);
    this.setState({ selectedWeeks: selectedWeeksAfterDelete });
    const selectedWeeksInYearInfoFormat = this.convertSelectedWeeksToYearInfo(selectedWeeksAfterDelete);
    this.props.onSelect(selectedWeeksInYearInfoFormat);
  };

  inSelectedWeeks = (weekKey) => this.state.selectedWeeks.includes(weekKey);

  addElements = (key, values) => {
    if (!Array.isArray(values)) values = [values];
    if (key === 'selectedWeeks') {
      values = values
        .map((value) => {
          let yw = value.toString().split('-');
          if (Object.prototype.hasOwnProperty.call(this.state.yearInfo, yw[0])) this.getYearInfo(yw[0]);
          let weekInfo = this.state.yearInfo[yw[0]] && this.state.yearInfo[yw[0]].weeks.filter((i) => i.key === value);
          return weekInfo && weekInfo[0] && weekInfo[0].disabled ? null : value;
        })
        .filter((_) => _);
    }
    this.setState((prevState) => ({
      [key]: [...prevState[key], ...values].sort(),
    }));
  };

  removeElements = (key, values) => {
    if (!Array.isArray(values)) values = [values];
    this.setState((prevState) => ({
      [key]: prevState[key].filter((elm) => !values.includes(elm)),
    }));
  };

  toggleSelectWeek = (weekKey) => {
    const { limit } = this.props;
    const { selectedWeeks } = this.state;
    const isSelectedBefore = this.inSelectedWeeks(weekKey);

    if (isSelectedBefore) {
      return this.removeElements('selectedWeeks', [weekKey]);
    }
    const newLength = selectedWeeks.length + 1;
    if (newLength > limit) return;
    return this.addElements('selectedWeeks', [weekKey]);
  };

  filterDisplayedWeeks = (yearInfo) => {
    const { disablePast } = this.props;
    const { selectedScholarYear } = this.state;

    const shouldFilterPastWeeks = disablePast && this.currentScholarYear() === selectedScholarYear;
    return yearInfo.weeks.map((week) => ({
      ...week,
      disabled: week.disabled || (shouldFilterPastWeeks && week.end < this.currentDay),
    }));
  };

  renderYearSelection = () => {
    const { classes } = this.props;
    const { selectedScholarYear } = this.state;

    const canForward = selectedScholarYear === this.currentScholarYear();
    const canBack = selectedScholarYear === this.nextScholarYear();

    const arrowBackClasses = cx({
      [classes.arrowCannot]: !canBack,
      [classes.arrowCan]: canBack,
    });

    const arrowForwardClasses = cx({
      [classes.arrowCannot]: !canForward,
      [classes.arrowCan]: canForward,
    });

    // TODO may be simplified (less div) and more accessible (text should be in textual html tag)
    return (
      <div className={classes.paperAndArrows}>
        <div className={classes.arrowContainer}>
          {canBack && (
            <>
              <ArrowBack className={arrowBackClasses} fontSize='large' onClick={() => canBack && this.handlePrev()} />
              <span>Année scolaire précédente</span>
            </>
          )}
        </div>

        <Paper className={classes.paper}>
          <span className={classes.selectedYear}>{selectedScholarYear}</span>
        </Paper>

        <div className={classes.arrowContainer}>
          {canForward && (
            <>
              <span>Année scolaire suivante</span>
              <ArrowForward
                className={arrowForwardClasses}
                fontSize='large'
                onClick={() => canForward && this.handleNext()}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  renderHeaderMessage = () => {
    const { classes, limit, headerMessage } = this.props;
    let messages = [
      limit && (
        <Fragment key='limit-message'>
          Merci de sélectionner les semaines de stage de votre collège <br />({limit} maximum)
        </Fragment>
      ),
      headerMessage,
    ].filter((_) => _);
    return messages && <h4 className={classes.title}>{messages}</h4>;
  };

  getScholarYearWeeks = (scholarYear) => {
    const [firstYear, secondYear] = scholarYear.split('/');

    const scholarYearInfo = {
      start: startOfISOWeek(new Date(firstYear, SEPTEMBER, 1)),
      end: endOfISOWeek(new Date(secondYear, JULY, 8)),
    };

    const firstYearInfo = this.getYearInfo(firstYear);
    const secondYearInfo = this.getYearInfo(secondYear);

    const allWeeks = [...firstYearInfo.weeks, ...secondYearInfo.weeks];

    const scholarYearWeeks = allWeeks.filter((week) => {
      const weekStart = new Date(week.start);
      const weekEnd = new Date(week.end);
      return (
        (weekStart >= scholarYearInfo.start && weekStart <= scholarYearInfo.end) ||
        (weekEnd >= scholarYearInfo.start && weekEnd <= scholarYearInfo.end)
      );
    });

    scholarYearInfo.weeks = scholarYearWeeks;
    scholarYearInfo.nbWeeks = scholarYearWeeks.length;

    return this.filterDisplayedWeeks(scholarYearInfo);
  };

  renderCalendarYear = () => {
    const {
      isOpen,
      selectedScholarYear,
      dateFormat: { toView },
    } = this.state;

    if (isOpen) {
      const { limit } = this.props;
      const scholarYearWeeks = this.getScholarYearWeeks(selectedScholarYear);
      return (
        <Table>
          <TableHead>
            <TableRow align='left'>
              <TableCell />
              <TableCell align='center'>Du</TableCell>
              <TableCell align='center'>Au</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scholarYearWeeks.map((datas) => (
              <TableRow key={datas.key}>
                <TableCell align='left'>
                  <CustomCheckbox
                    color='primary'
                    value={datas.key}
                    disabled={
                      datas.disabled
                        ? datas.disabled
                        : !this.inSelectedWeeks(datas.key) && this.state.selectedWeeks.length === limit
                    }
                    checked={this.inSelectedWeeks(datas.key)}
                    onChange={() => this.toggleSelectWeek(datas.key)}
                  />
                  <span>Semaine {datas.week}</span>
                </TableCell>
                <TableCell align='center'>{format(new Date(datas.start), toView)}</TableCell>
                <TableCell align='center'>{format(new Date(datas.end), toView)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
  };

  renderModalContent = () => {
    const { classes } = this.props;
    if (this.state.isOpen) {
      return (
        <div className={classes.root}>
          {this.renderYearSelection()}
          {this.renderHeaderMessage()}
          <small>{this.props.hideSubtitle == undefined ? this.inputLabel() : false}</small>
          {this.renderCalendarYear()}
        </div>
      );
    } else {
      return [];
    }
  };

  renderModal = () => (
    <SweetModal
      maxWidth='md'
      title={this.props.title || 'Période de disponibilité'}
      isOpen={this.state.isOpen}
      inputs={this.renderModalContent()}
      toggleModal={this.handleClose}
      submit={this.handleSubmit}
    />
  );

  renderSelectedWeekChip = (weekKey, haveManyYears) => {
    const weekInfo = this.getWeekInfoFromWeekKey(weekKey);
    if (!weekInfo) {
      return null;
    }
    return (
      <CustomChip
        key={`selected_chip_${weekKey}`}
        sep
        bold
        color='primary'
        onDelete={() => this.onDeleteWeekChip(weekKey)}>
        s{weekInfo.key} - {format(Date.parse(weekInfo.start), `dd/MM${haveManyYears ? '/yyyy' : ''}`)} au{' '}
        {format(Date.parse(weekInfo.end), `dd/MM${haveManyYears ? '/yyyy' : ''}`)}
      </CustomChip>
    );
  };

  render = () => {
    const { classes, success, error, label } = this.props;
    const { isOpen, currentYear, selectedWeeks } = this.state;

    const arrowDropDownClasses = cx({
      [classes.arrowDropDown]: true,
      [classes.arrowDropDownOpen]: isOpen,
    });

    const haveManyYears = selectedWeeks
      .map((wy) => Number(wy.toString().split('-')[0]))
      .some((y) => y !== Number(currentYear));

    return (
      <>
        {/* Modal (closed by default) */}
        {this.renderModal()}

        {/* Input */}
        <div className={classes.container}>
          <div className={classes.container}>
            <CustomInput
              success={success}
              error={error}
              labelText={label}
              value={this.inputLabel()}
              inputProps={{
                readOnly: true,
                onClick: this.handleOpen,
              }}
            />
            <ExpandMore className={arrowDropDownClasses} />
          </div>
          {selectedWeeks.length === 0 && this.props.isWeekPeriodAsked && (
            <div className={classes.errorText}>Merci d'ajouter au moins une semaine de disponibilité</div>
          )}
          {selectedWeeks.length > 0 && (
            <div>{selectedWeeks.map((weekKey) => this.renderSelectedWeekChip(weekKey, haveManyYears))}</div>
          )}
        </div>
      </>
    );
  };
}

MultipleWeeksPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  disablePast: PropTypes.bool,
  title: PropTypes.string,
  headerMessage: PropTypes.node,
  onSelect: PropTypes.func,
  value: PropTypes.array,
  isPro: PropTypes.bool,
  limit: PropTypes.number,
  hideSubtitle: PropTypes.bool,
  isWeekPeriodAsked: PropTypes.bool,
};

MultipleWeeksPicker.defaultProps = {
  isWeekPeriodAsked: false,
};

// noinspection JSUnusedGlobalSymbols
export default withStyles(multipleWeeksPickerStyle)(MultipleWeeksPicker);
